import React from "react"
import { graphql } from "gatsby"
import HeaderSecondary from "../components/headerSecondary"
import FooterGlobal from "../components/FooterGlobal"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import ReadyStarted from "../components/ReadyStarted"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { FaCheckCircle } from "react-icons/fa"
import { FaArrowRight } from "react-icons/fa"
import MainNav from "../components/MainNav"

export const getPortfolioData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "portfolio") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
      portfolios {
        nodes {
          id
          portfolio_acf {
            companyName
            description
            image {
              sourceUrl
            }
            services
            results
            websiteLink
          }
        }
      }
    }
  }
`

const Portfolio = ({ data, active }) => {
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
      portfolios: { nodes: portfolios },
    },
  } = data

  return (
    <>
      <Layout
        announcement={!announcement ? "" : announcement}
        countdownData={!countdownData ? "" : countdownData}
      >
        <SEO title={sitemetatitle} description={siteMetaDescription} />
        <MainNav active="portfolio" />
        <HeaderSecondary
          // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}
          active="portfolio"
          heading="Digital Solution For Local Business"
          subheading="As we like to say, it's not enough to just build a website. We believe in driving traffic, creating a memorable brand, connecting with your audience and helping you to convey your passion to that audience. Check out some of our recent projects here."
          // background={heroBG}
          // contact={contactNumber}
        />
        <section className="portfolio-page container">
          <ul className="portfolio-page__list list-unstyled">
            {portfolios
              .map((item, index) => (
                <li key={item.id} className="portfolio-page__item">
                  <div className="portfolio-page__imgWrapper">
                    <img
                      className="portfolio-page__img"
                      src={item.portfolio_acf.image.sourceUrl}
                      alt="GreatWeb Case Study"
                    />
                  </div>
                  <div className="portfolio-page__details">
                    <h3 className="portfolio-page__heading section-title">
                      {item.portfolio_acf.companyName}
                    </h3>
                    <p className="portfolio-page__description">
                      {item.portfolio_acf.description}
                    </p>
                    <ul className="portfolio-page__tags list-unstyled">
                      {item.portfolio_acf.services.map((item, index) => (
                        <li key={index} className="portfolio-page__tags--item">
                          <p className="badge badge-danger">{item}</p>
                        </li>
                      ))}
                    </ul>

                    <ul className="portfolio-page__results list-unstyled">
                      {item.portfolio_acf.results.map((item, index) => (
                        <li
                          key={index}
                          className="portfolio-page__results--item"
                        >
                          <div className="portfolio-page__results--iconWrapper">
                            <FaCheckCircle className="portfolio-page__results--icon far fa-check-circle" />
                          </div>
                          <p className="portfolio-page__results--text">
                            {item}
                          </p>
                        </li>
                      ))}
                    </ul>

                    <div className="portfolio-page__visitLink">
                      <a
                        className="mr-2"
                        href={`https://${item.portfolio_acf.websiteLink}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visit Website
                      </a>

                      <FaArrowRight />
                    </div>
                  </div>
                </li>
              ))
              .reverse()}
            <ReadyStarted color="primary" />
            <BookStrategyBtnPrimary />
          </ul>
        </section>
        <FooterGlobal
          companyAddress={companyAddress}
          companyContactNumber={companyContactNumber}
          companyOverview={companyOverview}
          facebookLink={facebookLink}
          linkedinLink={linkedinLink}
          supportEmail={supportEmail}
          twiiterLink={twiiterLink}
        />
      </Layout>
    </>
  )
}

export default Portfolio
